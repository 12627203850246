import { Swiper, Controller, Lazy } from "swiper";
import 'swiper/css';

class ProductsSwiper {
    /** @param {Swiper} */
    #imagesSwiper;
    /** @param {Swiper} */
    #textsSwiper;
    /** @param {HTMLElement} */
    imagesLogo;
    /** @param {HTMLElement} */
    leaf1;
    /** @param {HTMLElement} */
    leaf1Img;
    /** @param {HTMLElement} */
    leaf2;
    /** @param {HTMLElement} */
    leaf2Img;
    /** @param {HTMLElement} */
    priceList;
    /** @param {HTMLInputElement} */
    amountInput;
    /** @param {Number} */
    activeProductVariantID;

    static productImgDegStep = 65; // deg. Поворот картинки за один шаг свайпа
    static productImgMinScale = 0.56; // Минимальный scale() для картинки
    static productPriceMaxTranslateY = 150; // Максимальный translateY() для цены

    /**
     * @param {HTMLElement} imagesSwiperContainer
     * @param {HTMLElement} textsSwiperContainer
     * */
    constructor(imagesSwiperContainer, textsSwiperContainer) {
        // Логотип на заднем плане
        this.imagesLogo = document.querySelector(".products-list-images-logo img");

        // Листики :)
        this.leaf1 = document.querySelector(".products-list-images-leaf.leaf-1 .leaf");
        this.leaf1Img = this.leaf1.querySelector("img");
        this.leaf2 = document.querySelector(".products-list-images-leaf.leaf-2 .leaf");
        this.leaf2Img = this.leaf2.querySelector("img");

        // Все прайсы для наших продуктов
        this.priceList = document.querySelectorAll(".products-list-prices-wrapper .products-item-price-block");
        if (this.priceList) {
            this.priceList = Array.from(this.priceList);
        }

        // input со значением количества
        this.amountInput = document.querySelector(".fn_product_amount_input");

        // Свайпер для изображений товаров
        this.#imagesSwiper = new Swiper(imagesSwiperContainer, {
            modules: [ Controller, Lazy ],
            rewind: true,
            slidesPerView: 1.8,
            centeredSlides: true,
            lazy: {
                enabled: true,
                loadPrevNext: true,
                loadPrevNextAmount: 2,
            },
            on: {
                afterInit: (swiper) => this.#transform(0, 0, 0, swiper)
            }
        });

        // Свайпер для текстовой информации о товарах
        this.#textsSwiper = new Swiper(textsSwiperContainer, {
            modules: [ Controller ],
            rewind: true,
            slidesPerView: 1,
            centeredSlides: true,
        });

        // Зададим связь между свайперами, чтобы они скролились синхронно
        this.#imagesSwiper.controller.control = this.#textsSwiper;
        this.#textsSwiper.controller.control = this.#imagesSwiper;

        this.#init();
    }

    #transform(slideProgress, activeIndex, transition = 0, imagesSwiper = this.#imagesSwiper) {
        const rotateLogoDeg =
            -1 * (
                ProductsSwiper.productImgDegStep * slideProgress
                +
                activeIndex * ProductsSwiper.productImgDegStep
            )
        ;

        this.#transformLogo(rotateLogoDeg, transition)
        this.#transformLeaf1(rotateLogoDeg, transition);
        this.#transformLeaf2(rotateLogoDeg, transition);

        imagesSwiper.slides.forEach((slide, index) => {
            const iterateProgress =
                -1 * (
                    slideProgress - (index - activeIndex)
                )
            ;

            const productImg = slide.querySelector("img");
            if (productImg) {
                ProductsSwiper.#cssRotateElement(
                    slide.querySelector("img"),
                    iterateProgress * ProductsSwiper.productImgDegStep,
                    transition
                );
            }

            const maxProductImgScale = 1
                , minProductImgScale = ProductsSwiper.productImgMinScale
                , imageScale = (
                    minProductImgScale + (
                        maxProductImgScale - minProductImgScale
                    ) * (
                        1 - Math.abs(iterateProgress)
                    )
                )
            ;
            ProductsSwiper.#cssScaleElement(
                slide.querySelector(".products-item__img"),
                ProductsSwiper.#limitNumber(imageScale, minProductImgScale, maxProductImgScale),
                transition
            );

            const price = this.priceList.filter(price => price.dataset.key === slide.dataset.key)[0];
            if (price) {
                const maxPriceTranslateY = ProductsSwiper.productPriceMaxTranslateY
                    , minPriceTranslateY = 0
                    , priceScale = (
                        minPriceTranslateY + (
                            maxPriceTranslateY - minPriceTranslateY
                        ) * Math.abs(iterateProgress)
                    )
                ;

                ProductsSwiper.#cssTranslateYElement(
                    price,
                    ProductsSwiper.#limitNumber(priceScale, minPriceTranslateY, maxPriceTranslateY),
                    transition
                );
            }
        })
    }

    #transformLeaf1(rotate, transition = 0) {
        if (this.leaf1) {
            ProductsSwiper.#cssRotateElement(
                this.leaf1,
                1.2 * rotate,
                transition
            );

            ProductsSwiper.#cssRotateElement(
                this.leaf1Img,
                -2 * rotate,
                transition
            );
        }
    }

    #transformLeaf2(rotate, transition = 0) {
        if (this.leaf2) {
            ProductsSwiper.#cssRotateElement(
                this.leaf2,
                1.2 * rotate,
                transition
            );

            ProductsSwiper.#cssRotateElement(
                this.leaf2Img,
                -1.6 * rotate,
                transition
            );
        }
    }

    #transformLogo(rotate, transition = 0) {
        if (this.imagesLogo) {
            ProductsSwiper.#cssRotateElement(
                this.imagesLogo,
                rotate,
                transition
            );
        }
    }

    /**
     * @param {HTMLElement} element
     * @param {Number} deg
     * @param {Number} transition
     * */
    static #cssRotateElement(element, deg, transition = 0) {
        element.style.transition = transition ? `${transition}ms` : "";
        element.style.transform = `rotate(${deg}deg)`;
    }

    /**
     * @param {HTMLElement} element
     * @param {Number} scale
     * @param {Number} transition
     * */
    static #cssScaleElement(element, scale, transition = 0) {
        element.style.transition = transition ? `${transition}ms` : "";
        element.style.transform = `scale(${scale})`;
        element.style.transformOrigin = `bottom center`;
    }

    /**
     * @param {HTMLElement} element
     * @param {Number} translateY
     * @param {Number} transition
     * @param {String} unit
     * */
    static #cssTranslateYElement(element, translateY, transition = 0, unit = "%") {
        element.style.transition = transition ? `${transition}ms` : "";
        element.style.transform = `translateY(${translateY}${unit})`;
    }

    static #limitNumber(val, min, max) {
        return Math.min(max, Math.max(min, val));
    }

    #init() {
        this.activeProductVariantID = this.#imagesSwiper.slides[this.#imagesSwiper.realIndex].dataset.key;

        this.#initSwiperEvents();

        window.addEventListener("cart_updated", () => this.#changeActiveProduct());
    }

    #initSwiperEvents() {
        let progressStart = null
            , activeIndex = 0
            , touchEnded = false;

        this.#imagesSwiper.on('slideChange', swiper => {
            if (!touchEnded) return;

            activeIndex = this.#imagesSwiper.realIndex;
            this.activeProductVariantID = this.#imagesSwiper.slides[activeIndex].dataset.key;

            this.#transform(0, activeIndex, swiper.params.speed);
            this.#changeActiveProduct();
        });

        this.#textsSwiper.on('touchStart', () => {
            activeIndex = this.#imagesSwiper.realIndex;
            progressStart = this.#imagesSwiper.progress;
            touchEnded = false;
        });

        this.#imagesSwiper.on('touchStart', swiper => {
            activeIndex = this.#imagesSwiper.realIndex;
            progressStart = swiper.progress;
            touchEnded = false;
        });

        this.#textsSwiper.on('touchEnd', () => {
            touchEnded = true;
            activeIndex = this.#imagesSwiper.realIndex;
            this.#transform(0, activeIndex, this.#imagesSwiper.params.speed);
        });

        this.#imagesSwiper.on('touchEnd', swiper => {
            touchEnded = true;
            this.#transform(0, activeIndex, swiper.params.speed);
        });

        this.#imagesSwiper.on('progress', (swiper, progress) => {
            if (touchEnded) return;

            const slidesCount = swiper.slides.length
                , slideProgress = (progress - progressStart) / (1 / (slidesCount - 1));

            this.#transform(slideProgress, activeIndex);
        });
    }

    #changeActiveProduct() {
        const cart = window.cart
            , activePurchase = cart.purchases[this.activeProductVariantID]
        ;

        this.amountInput.dataset.vid = this.activeProductVariantID;
        this.amountInput.value = activePurchase ? activePurchase.amount : 0;
    }
}

export default ProductsSwiper
