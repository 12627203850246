class Ajax extends XMLHttpRequest {
    /** @param {Object} */
    #config;
    form = new FormData();

    /**
     * @param {Object} config
     * @param {Object} config.data
     * @param {String} config.url
     * @param {Function} config.success
     * @param {Function} config.error
     * */
    constructor(config) {
        super();

        this.#config = config;

        Object.keys(this.#config.data).forEach((key) => {
            this.form.set(key, this.#config.data[key]);
        });

        this.open("POST", this.#config.url);

        this.success = this.#config.success || this.success;
        this.error = this.#config.error || this.error;

        this.addEventListener('loadend', () => {
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                this.success(JSON.parse(this.response));
            } else {
                this.error(this);
            }
        })
    }

    success(response) {}

    error() {
        console.log("Ajax Error.", this);
    }

    send() {
        super.send(this.form);
    }
}

export default Ajax;
