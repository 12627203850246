import ProductsSwiper from "./modules/ProductsSwiper";
import Ajax from "./modules/Ajax";

const ajaxUrls = {
    addToCart: "/ajax/cart.php",
    changePurchase: "/ajax/cart_ajax.php",
};

// Page Loader
(function () {
    window.onload = function () {
        const pageBox = document.querySelector(".page-box.loading");

        if (!pageBox) return;

        pageBox.style.display = "none";
        pageBox.classList.remove("loading");
        pageBox.classList.add("loaded");
        pageBox.style.display = "";
    }
})();

document.addEventListener('DOMContentLoaded', function () {
    function updateWindowCart(cart) {
        window.cart = cart;
        // выкинем событие, чтобы можно было отслеживать обновление корзины
        window.dispatchEvent(new Event("cart_updated"));
    }


    /**
     * Если мы на странице продукта, запускаем свайперы
     * */
    if (document.querySelector("#fn_products_content")) {
        new ProductsSwiper(
            document.querySelector(".products-list-images .swiper"),
            document.querySelector(".products-list-texts .swiper")
        );
    }
    /** ======================== ||||| ======================== */


    /**
     * +/- в товаре
     * */
    function initAmountChanger(wrapper) {
        wrapper.querySelectorAll(".fn_product_amount").forEach(amount => {
            const plus = amount.querySelector(".fn_product_amount_plus")
                , minus = amount.querySelector(".fn_product_amount_minus")
                , input = amount.querySelector(".fn_product_amount_input")
            ;

            plus.addEventListener("click", () => {
                input.stepUp();
                input.dispatchEvent(new Event("change"));
            });

            minus.addEventListener("click", () => {
                input.stepDown();
                input.dispatchEvent(new Event("change"));
            });
        });
    }
    initAmountChanger(document);
    /** ======================== ||||| ======================== */


    /**
     * Изменение количества продуктов в корзине (страница продуктов)
     * */
    function onChangeProductAmount(productAmount) {
        const purchase = new Ajax({
            url: ajaxUrls.addToCart,
            data: {
                variant: parseInt(productAmount.dataset.vid),
                amount: parseInt(productAmount.value),
            },
            success(response) {
                const cart = response.cart
                    , informerElement = document.getElementById("fn_cart_informer")
                ;

                updateWindowCart(cart);
                informerElement.innerHTML = response.html.cart_informer;

                if (cart.total_products > 0) {
                    informerElement.classList.remove("empty");
                } else {
                    informerElement.classList.add("empty");
                }
            }
        });

        purchase.send();
    }
    function initProductAmount(wrapper) {
        wrapper.querySelectorAll(".fn_ajax_product_amount").forEach(productAmount => {
            productAmount.addEventListener("change", () => onChangeProductAmount(productAmount));
        });
    }
    initProductAmount(document);
    /** ======================== ||||| ======================== */


    /**
     * Изменение количества продуктов в корзине (страница корзины)
     * */
    function onChangeCartAmount(productAmount) {
        const purchase = new Ajax({
            url: ajaxUrls.changePurchase,
            data: {
                variant: parseInt(productAmount.dataset.vid),
                amount: parseInt(productAmount.value),
            },
            success(response) {
                const cart = response.cart
                    , informerElement = document.getElementById("fn_cart_informer")
                    , cartPurchases = document.getElementById("fn_purchases")
                ;

                updateWindowCart(cart);
                informerElement.innerHTML = response.html.cart_informer;
                cartPurchases.innerHTML = response.html.cart_purchases;

                // Проинициализируем js для загруженной верстки
                initAmountChanger(cartPurchases);
                initCartAmount(cartPurchases);

                if (cart.total_products > 0) {
                    informerElement.classList.remove("empty");
                } else {
                    informerElement.classList.add("empty");
                }
            }
        });

        purchase.send();
    }
    function initCartAmount(wrapper) {
        wrapper.querySelectorAll(".fn_ajax_cart_amount").forEach(productAmount => {
            productAmount.addEventListener("change", () => onChangeCartAmount(productAmount));
        });
    }
    initCartAmount(document);
    /** ======================== ||||| ======================== */
})
